import { Heading, Carousel } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect, useState } from 'react';

import { HolidayCollectionProps as HolidayCollectionType, ProductTrackingList } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import {
  HolidayCard,
  CollectionHotelFields,
} from '@Components/LayoutComponents/HolidayCollection/HolidayCard';
import { SectionContainer } from '@Components/SectionContainer';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { useProductImpressionTracking } from '@Tracking';

interface HotelsProps extends ClassNameProps {
  items: CollectionHotelFields[];
}

export const Hotels: React.FC<HotelsProps> = ({ className, items }) => {
  const { t } = useTranslation();
  const { recentlyViewedHotels } = useUserPreferences();

  const [content, setContent] = useState<{
    title: string;
    type: ProductTrackingList;
    items: CollectionHotelFields[];
    linkTarget: HolidayCollectionType['linkTarget'];
  }>({
    title: t('bookedRecently'),
    type: 'PopularHotels',
    items,
    linkTarget: 'hdp',
  });

  const recentHotels = typeof window !== 'undefined' && recentlyViewedHotels.get();

  useEffect(() => {
    if (recentHotels && recentHotels.length) {
      setContent({
        title: t('recentlyViewedHotels'),
        type: 'RecentlyViewedHotels',
        items: recentHotels.map((h) => h.accommodation).filter(Boolean),
        linkTarget: 'panda',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!content.items.length) {
    return null;
  }

  return (
    <SectionContainer
      data-id="popular-hotels"
      className={className}
      sx={{
        paddingTop: '2xl',
      }}
    >
      <Heading
        variant="largebold"
        as="h2"
        sx={{
          marginBottom: 's',
        }}
      >
        {content.title}
      </Heading>
      <Carousel
        resetScroll
        sx={{
          marginX: [0, null, '-xs'],
          scrollPaddingLeft: [0, 'xs'],
          scrollPaddingRight: [0, 'xs'],
          paddingX: [0, 'xs'],
          paddingBottom: 'xs',
        }}
      >
        {content.items.map((hotel, index) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const ref = useProductImpressionTracking({
            masterId: hotel.id,
            position: index + 1,
            ...hotel,
            list: content.type,
          });

          return (
            <HolidayCard
              linkTarget={content.linkTarget}
              internalLink={['srp', 'hdp_modal'].includes(content.linkTarget)}
              key={`${hotel.id}-${content.type}`}
              ref={ref}
              type={content.type}
              {...hotel}
            />
          );
        })}
      </Carousel>
    </SectionContainer>
  );
};
